import { queryOptions } from "@tanstack/react-query";
import { genQueries } from "src/react-query";
import {
  getEmailThread as getCommsEmailThread,
  GetEmailThreadParams as GetCommsEmailThreadParams,
  GetEmailThreadResponse as GetCommsEmailThreadResponse,
  getEmailThreadsWithRetry as getCommsEmailThreadsWithRetry,
  GetEmailThreadsParams as GetCommsEmailThreadsParams,
  GetEmailThreadsResponse as GetCommsEmailThreadsResponse,
} from "src/communications/thread-list/email-threads.resource";
import {
  getEmailThreads as getInboxEmailThreads,
  GetEmailThreadsParams as GetInboxEmailThreadsParams,
  GetEmailThreadsResponse as GetInboxEmailThreadsResponse,
} from "src/inbox/inbox.resource";

export const emailThreadQueries = genQueries("email", ({ genKey }) => ({
  commsEmailThread: (params: GetCommsEmailThreadParams) =>
    queryOptions<GetCommsEmailThreadResponse>({
      queryKey: genKey("comms-thread", params),
      queryFn: () => getCommsEmailThread(params),
      enabled: !!params.threadId,
      staleTime: 5 * 60 * 1000, // 5min
    }),
  commsEmailThreads: (params: GetCommsEmailThreadsParams) =>
    queryOptions<GetCommsEmailThreadsResponse>({
      queryKey: genKey("comms-threads", params),
      queryFn: () => getCommsEmailThreadsWithRetry(params),
      staleTime: 5 * 60 * 1000, // 5min
    }),
  inboxEmailThreads: (
    params: GetInboxEmailThreadsParams,
    onFetchComplete: (data: GetInboxEmailThreadsResponse) => void = () => {},
    enabled: boolean = true
  ) =>
    queryOptions<GetInboxEmailThreadsResponse>({
      queryKey: genKey("inbox-threads", params),
      queryFn: () =>
        getInboxEmailThreads(params).then((data: GetInboxEmailThreadsResponse) => {
          onFetchComplete(data);
          return data;
        }),
      staleTime: 5 * 60 * 1000, // 5min
      meta: {
        errorOptions: (error: any) => {
          if (error.status === 504) {
            console.error("Nylas timeout error: ", error);
            return {
              skipLogging: true,
              showToast: false,
            };
          }
        },
      },
      enabled,
    }),
}));
